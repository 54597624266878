import React from 'react';
import styled from 'styled-components';
import {Table, Button, Tag} from 'antd';
import moment from 'moment';
import {navigate} from 'gatsby';

import * as AppContext from '../../AppContext';
import {BellOutlined} from '../../Widgets/Icon';
import Calendar from './Calendar';

function CalendarPage(props) {
  const app = React.useContext(AppContext.Context);
  const [records, setRecords] = React.useState(null);
  let tomorrow = new Date(Date.now() + 24 * 3600 * 1000);

  const fetchParticipantData = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let participantList = await app.actions.memberGetParticipantList();
      let activities = await app.actions.memberGetActivitiesDetilByIds(
        participantList.map((item) => item.activity),
      );

      let records = participantList.map((item) => {
        let findIndex = activities.findIndex(
          (activity) => activity.id === item.activity,
        );

        return {...item, activity_detail: {...activities[findIndex]}};
      });

      setRecords(records);
    } catch (err) {
      console.warn(err);
    }
    app.actions.setLoading(false);
  }, [app.actions]);

  React.useEffect(() => {
    fetchParticipantData();
  }, [fetchParticipantData]);

  const columns = [
    {
      title: '編號',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => <div>{record.activity.slice(0, 6)}</div>,
    },
    {
      title: '活動名稱',
      dataIndex: 'name',
      key: 'name',
      render: (_, record, index) => <div>{record.activity_detail.name}</div>,
    },
    {
      title: '活動時間',
      dataIndex: 'name',
      key: 'name',
      render: (_, record, index) => (
        <div>
          {moment(record.activity_detail.date_start).format('YYYY-MM-DD')} -{' '}
          {moment(record.activity_detail.date_end).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      title: '查看',
      dataIndex: 'check',
      key: 'check',
      render: (_, record) => (
        <Button
          onClick={() =>
            navigate(
              `/event?activity=${record.activity}&participant=${record.id}`,
            )
          }>
          查看
        </Button>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="row-container">
        <div className="header">
          <h3 className="title">報名紀錄</h3>
        </div>

        <div className="notify">
          <Button
            type="outline"
            onClick={() => app.actions.setMemberNotifications([])}>
            <BellOutlined color="rgba(0, 0, 0, 0.65)" />
          </Button>
          <div className="badge" />
        </div>
      </div>

      <div className="column-container">
        <Table columns={columns} dataSource={records} />
      </div>

      <div className="column-container">
        <div className="header">
          <h3 className="title">我的日曆</h3>
          <span className="hint">提示：顯示「已確認」參加的活動及時段。</span>
        </div>

        <Calendar
          records={records}
          defaultValue={tomorrow}
          selectedDt={tomorrow}
          disablePast={false}
          year={tomorrow.getFullYear()}
          month={tomorrow.getMonth()}
          minDate={new Date()}
          onSelect={(dt) => console.log(JSON.stringify(dt, null, 2))}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .row-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;

    & > .header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }

    & > .notify {
      position: relative;
      padding-bottom: 20px;

      & > .badge {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ec8e25;
        top: -4px;
        right: -4px;
      }
    }
  }

  & > .column-container {
    margin: 20px 20px 50px;

    & > .header {
      margin-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 15px;
      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }

      & > .hint {
        font-size: 14px;
        color: #4a4a4a;
      }
    }
  }
`;

export default CalendarPage;
