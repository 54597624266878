import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

function DateContent(props) {
  let {date, records} = props;

  if (!records || records.length === 0) {
    return <Wrapper></Wrapper>;
  }

  if (records.length > 0) {
    return records.map((record) => {
      return (
        <div>
          {record.duration.map((item) => {
            if (item.date === date && item.state === 'confirmed') {
              return (
                <div style={{marginTop: 5, marginBottom: 5}}>
                  <span
                    style={{
                      backgroundColor: 'lightgreen',
                      whiteSpace: 'pre-wrap',
                      fontSize: 10,
                      color: '#4a4a4a',
                    }}>
                    ＃{record.activity_detail.name} {item.time[0]}~
                    {item.time[1]}
                  </span>
                </div>
              );
            }

            return <div />;
          })}
        </div>
      );
    });
  }
}

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
  white-space: pre-wrap;
  overflow: auto;

  font-size: 12px;
  color: #4a4a4a;
`;

export default DateContent;
